<template>
  <div>
    <my-header></my-header>
    <div class="box">
      <div class="item1" v-if="companyitem[0]">
        <img :src="companyitem[0].companyLogo" alt="" />
      </div>
      <div class="item2">
        <p>申请加入</p>
        <el-input
          type="textarea"
          placeholder="请输入申请备注"
          maxlength="150"
          show-word-limit
          v-model="message"
        >
        </el-input>
        <el-button type="primary" @click="submitApplication"
          >提交申请</el-button
        >
      </div>
      <!-- 个人用户显示 -->
      <div class="item3">
        <p>申请记录</p>
        <!-- <el-button type="primary" plain @click="submitAgent">申请商家业务员</el-button> -->
        <el-table
          v-if="(roleId != 0 && roleId != 5) || roleId == 2"
          :data="ApplicationList"
          border
          style="width: 100%"
        >
          <el-table-column prop="updateTime" label="日期" width="180">
          </el-table-column>
          <el-table-column prop="message" label="申请备注" width="780">
          </el-table-column>
          <el-table-column prop="status" label="进度"> </el-table-column>
        </el-table>
        <el-table v-else :data="ApplicationList" border style="width: 100%">
          <el-table-column prop="updateTime" label="日期" width="180">
          </el-table-column>
          <el-table-column prop="message" label="申请备注" width="500">
          </el-table-column>
          <el-table-column prop="status" label="进度"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="lookhandleEdit(scope.$index, scope.row)"
                >查看详情</el-button
              >
              <el-button
                v-if="scope.row.status == '未审核'"
                size="mini"
                type="danger"
                @click="dealWithApplication(scope.$index, scope.row)"
                >处理申请</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 申请列表显示 -->
        <!-- 查看详情 -->
        <el-dialog title="申请详情" :visible.sync="dialogTableVisible">
          <div class="detail">
            <div>
              <div>
                <span>申请时间</span>
              </div>
              <span v-text="k.updateTime"></span>
            </div>
            <div>
              <div>
                <span>申请人手机号</span>
              </div>
              <span
                v-text="detailItem.phone ? detailItem.phone : '没有留下手机号'"
              ></span>
            </div>
            <div>
              <div>
                <span>是否实名</span>
              </div>
              <span v-text="detailItem.ifcheck ? '已实名' : '未实名'"></span>
            </div>
            <div>
              <div>
                <span>性别</span>
              </div>
              <span v-text="detailItem.sex ? '男' : '女'"></span>
            </div>
            <div>
              <div>
                <span>区域</span>
              </div>
              <span
                v-text="
                  detailItem.region
                    ? `${detailItem.region[0]}${detailItem.region[1]}${detailItem.region[2]}`
                    : '没有留下地址'
                "
              ></span>
            </div>
            <div>
              <div><span>用户状态</span></div>
              <span v-text="detailItem.state == 1 ? '正常' : '冻结'"></span>
            </div>
            <div>
              <div>
                <span>申请备注</span>
              </div>
              <span v-text="k.message ? k.message : '没有留下备注'"></span>
            </div>
            <div>
              <div>
                <span>申请进度</span>
              </div>
              <span>{{ k.status }}</span>
            </div>
          </div>
        </el-dialog>
        <!-- 处理申请 -->
        <el-dialog
          title="处理申请"
          :visible.sync="dialogFormVisible"
          width="700px"
        >
          <el-form :model="form">
            <el-form-item label="备注名称" label-width="120px">
              <el-input
                v-model="form.memberName"
                autocomplete="off"
                style="width: 350px"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注电话" label-width="120px">
              <el-input
                v-model="form.memberPhone"
                autocomplete="off"
                style="width: 350px"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" label-width="120px">
              <el-select v-model="form.status" placeholder="请选择状态">
                <el-option label="同意" value="1"></el-option>
                <el-option label="拒绝" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmdealWithApplication"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
      <div class="item4" v-if="roleId == 0 || roleId == 5 || roleId == 2">
        <div>
          <span>员工列表</span>
          <el-input
            placeholder="请输入查询内容"
            v-model="input3"
            class="input-with-select"
          >
            <el-button
              slot="append"
              @click="getMemberList"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <!-- 选择搜索 -->
          <el-select
            v-model="input4"
            filterable
            placeholder="请选择状态"
            @input="getMemberList"
          >
            <el-option label="在职" value="0"> </el-option>
            <el-option label="暂离" value="1"> </el-option
            ><el-option label="离队" value="2"> </el-option>
          </el-select>
        </div>
        <template>
          <el-table :data="MemberList" style="width: 100%" border>
            <el-table-column prop="memberName" label="备注名" width="280">
            </el-table-column>
            <el-table-column prop="memberPhone" label="备注手机号" width="280">
            </el-table-column>
            <el-table-column prop="state" label="状态"> </el-table-column>
            <el-table-column prop="createTime" label="加入时间">
            </el-table-column>
          </el-table>
        </template>
        <el-pagination
          background
          :total="Membertotal"
          :page-size="MemberpageSize"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      // 查看详情显示隐藏
      dialogTableVisible: false,
      //   处理申请显示隐藏
      dialogFormVisible: false,
      //   详情信息
      detailItem: {},

      //   模糊查询员工
      input3: "",
      input4: "",
      //   当前点击下标
      k: Number,
      form: {
        memberName: "",
        memberPhone: "",
        status: "",
        applicationId: "",
      },
      // 商家信息
      companyitem: [],
      //   申请列表
      ApplicationList: [],
      //   员工列表
      MemberList: [],
      MemberpageNum: 1,
      MemberpageSize: 5,
      Membertotal: 2,
      //   申请备注
      message: "",
    };
  },
  // 权限信息
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
  },
  methods: {
    //   获取当前商家信息
    async getId() {
      const res = await this.$http({
        method: "get",
        url: `front/company/findList?id=${this.$route.query.id}`,
      });
      this.companyitem = res.data.data.list;
        console.log(this.companyitem);
        
    },
    // 提交申请
    submitApplication() {
      // const res = this.$http({
      //   method: "post",
      //   url: "front/company/checkCompanyJoin/submitApplication",
      //   data: {
      //     companyId: this.companyitem[0].id,
      //     message: this.message,
      //   },
      // });
      // console.log(res.data);
      this.$confirm('确定申请商家业务员吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(async() => {
          const res = await this.$http({
          method: "post",
          url: "front/company/checkCompanyJoin/submitApplication",
          data: {
          companyId: this.companyitem[0].id,
          message: this.message,
        },
        });
          if(res.data == 200){
            this.$message({
              type: 'success',
              message: '已提交申请,待审核!'
            });
          }
          this.reload();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消申请'
          });          
        });
    },
    // 获取申请列表信息
    async getApplication() {
      const res = await this.$http({
        method: "get",
        url: `front/company/checkCompanyJoin/getApplication`,
      });
      console.log(res.data.data);
      res.data.data.list.map((v, k) => {
        if (
          v.userId == sessionStorage.getItem("userId") &&
          v.companyId == this.$route.query.id
        ) {
          v.status == 0
            ? (v.status = "未审核")
            : v.status == 1
            ? (v.status = "已通过")
            : v.status == 2
            ? (v.status = "已拒绝")
            : (v.status = "");
          this.ApplicationList.push(v);
        } else if (
          (v.companyId == this.$route.query.id && this.roleId == 5) ||
          this.roleId == 0
        ) {
          v.status == 0
            ? (v.status = "未审核")
            : v.status == 1
            ? (v.status = "已通过")
            : v.status == 2
            ? (v.status = "已拒绝")
            : (v.status = "");
          this.ApplicationList.push(v);
        }
      });
        this.ApplicationList = res.data.data.list;
      console.log(this.ApplicationList);
    },
    // 查看详情
    lookhandleEdit(k, v) {
      this.dialogTableVisible = true;
      this.detailItem = v.userShowVo;
      this.k = this.ApplicationList[k];
      
      console.log(this.detailItem);
    },
    //   处理申请
    async dealWithApplication(k, v) {
      this.dialogFormVisible = true;
      this.form.applicationId = v.id;
    },
    //确定处理详情
    async confirmdealWithApplication() {
      const res = await this.$http({
        method: "post",
        url: "front/company/checkCompanyJoin/dealWithApplication",
        data: {
          applicationId: this.form.applicationId,
          memberName: this.form.memberName,
          memberPhone: this.form.memberPhone,
          status: this.form.status,
        },
      });
      console.log(res.data.status);
      if (res.data.status == 200) {
        this.reload();
      }
      this.dialogFormVisible = false;
    },
    handleCurrentChange(val) {
      this.MemberpageNum = val;
      this.getMemberList();
    },
    // 获取员工列表
    async getMemberList() {
      let state = "";
      if (this.input4 == "") {
        state = "";
      } else {
        state = `&state=${this.input4}`;
      }
      let memberName = "";
      let memberPhone = "";
      if (this.input3 == "") {
        memberName = "";
      } else {
        memberName = `&memberName=${this.input3}`;
        memberPhone = `&memberPhone=${this.input3}`;
      }
      console.log(memberName);
      let res = await this.$http({
        method: "get",
        url: `front/company/companyMember/getMemberList?companyId=${this.$route.query.id}${memberName}${state}&pageSize=${this.MemberpageSize}&pageNum=${this.MemberpageNum}`,
      });
      if (res.data.data.list) {
        let res = await this.$http({
          method: "get",
          url: `front/company/companyMember/getMemberList?companyId=${this.$route.query.id}${memberPhone}${state}&pageSize=${this.MemberpageSize}&pageNum=${this.MemberpageNum}`,
        });
        this.MemberList = [];
        res.data.data.list.map((v, k) => {
          v.state == 0
            ? (v.state = "在职")
            : v.state == 1
            ? (v.state = "暂离")
            : v.state == 2
            ? (v.state = "离队")
            : "未知";
          this.MemberList.push(v);
        });
        console.log(res.data.data);
        this.MemberpageNum = res.data.data.pageNum;
        this.MemberpageSize = res.data.data.pageSize;
        this.Membertotal = res.data.data.total;
      } else {
        this.MemberList = [];
        res.data.data.list.map((v, k) => {
          v.state == 0
            ? (v.state = "在职")
            : v.state == 1
            ? (v.state = "暂离")
            : v.state == 2
            ? (v.state = "离队")
            : "未知";
          this.MemberList.push(v);
        });
        this.MemberpageNum = res.data.data.pageNum;
        this.MemberpageSize = res.data.data.pageSize;
        this.Membertotal = res.data.data.total;
      }
    },
  },
  created() {
    this.getMemberList();
    this.getId();
    this.getApplication();
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding-bottom: 50px;
  min-width: 1200px;
  width: 80%;
  margin: 0 auto;
  margin-top: 160px;
  border: 1px solid #ddd;
  & > .item1 {
    width: 100%;
    img {
      height: 150px;
      margin-left: 40%;
    }
  }
  & > .item2 {
    margin: 0 30px;
    box-sizing: border-box;
    p {
      font-size: 18px;
      margin-left: 20px;
    }
    ::v-deep .el-textarea {
      margin: 10px 0;
    }
    ::v-deep .el-button {
      margin-left: 85%;
    }
  }
  & > .item3 {
    width: 94.5%;
    margin: 10px auto;
    p {
      font-size: 18px;
      margin-left: 20px;
      margin-bottom: 10px;
    }
    .el-button{
      float: right;
      margin-bottom: 10px;
    }
    .detail {
      div > div:nth-child(1) {
        display: inline-block;
        text-align: right;
        width: 120px;
        padding: 5px;
        padding-right: 15px;
        color: #666;
        font-size: 15px;
      }
      div span:nth-child(2) {
        width: 300px;
      }
    }
  }
  & > .item4 {
    width: 94.5%;
    margin: 10px auto;
    div:nth-child(1) {
      display: flex;
      span {
        display: inline-block;
        font-size: 18px;
        margin-left: 20px;
        line-height: 52px;
      }
      ::v-deep > .input-with-select {
        width: 300px;
        margin: 5px;
        margin-left: 10px;
      }
      ::v-deep .el-select {
        margin-top: 5px;
        width: 130px;
      }
    }
    ::v-deep .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin: 10px 40px;
    }
  }
}
</style>